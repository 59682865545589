<template>
  <!-- begin::My Addresses tabs for SenderAdmin & SenderEmployee Roles -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/pin-2-primary.svg"
      />
      <div>
        {{ $t("FREQUENT_ADDRESSES.TITLE_INDEX") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
        {{ t.Title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
        <div v-if="t.Name === 'basic'">
          <AddressesIndex />
        </div>

        <div v-else>
          <TenderRoutesIndex />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- end::My Addresses tabs -->
</template>

<script>
import { mapGetters } from "vuex";

import AddressesIndex from "@/view/pages/manager/addresses/frequent/Index.vue";
import TenderRoutesIndex from "@/view/pages/manager/addresses/tender/Index.vue";

export default {
  name: "MyAddressesTabs",
  components: {
    AddressesIndex,
    TenderRoutesIndex
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },
  data() {
    return {
      TabList: []
    };
  },
  mounted() {
    this.TabList = [
      {
        Position: 0,
        Name: "basic",
        Title: this.$i18n.t("FREQUENT_ADDRESSES.TITLE_INDEX_2")
      },
      {
        Position: 1,
        Name: "tender",
        Title: this.$i18n.t("TENDER_ROUTES.TITLE_INDEX")
      }
    ];
  }
};
</script>
