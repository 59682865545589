<template>
  <!--begin::Addresses index-->
  <div class="gray-box">
    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("FREQUENT_ADDRESSES.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-primary mb-3"
            @click="$router.push('/manager/addresses/new')"
          >
            <inline-svg src="/media/icons/add.svg" class="mr-3" />
            {{ $t("FREQUENT_ADDRESSES.TITLE_ADD") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-4">
          <label>
            {{ $t("FREQUENT_ADDRESSES.PROVINCE") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByProvinceID"
            @change="searchAddresses(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-8 pt-7 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.Nick }}
          </td>
          <td>
            {{ item.Street }}
          </td>
          <td>
            {{ item.PostalCode }}
          </td>
          <td>
            {{ item.City }}
          </td>
          <td>
            {{ getProvinceName(item.ProvinceID) }}
          </td>
          <td>
            <span
              class="mr-4 p-0"
              @click="startNewPriceSearchByAddress(item.AddressID)"
            >
              <inline-svg
                class="pricesearch-icon bg-tertiary"
                src="/media/icons/tarifas.svg"
              />
            </span>

            <span
              class="action-icon"
              @click="$router.push('/manager/addresses/edit/' + item.AddressID)"
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>

            <span class="action-icon ml-4" @click="deleteAddress(item)">
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchAddresses(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::Addresses index-->
</template>

<script>
import Swal from "sweetalert2";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import AddressService from "@/core/services/api/v2/address.service";
import AddressTypeService from "@/core/services/api/v2/addresstype.service";
import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import ProvinceService from "@/core/services/api/v2/province.service";

export default {
  name: "AddressesIndex",
  data() {
    return {
      TableHeaders: [
        { text: this.$t("FREQUENT_ADDRESSES.NICK"), value: "Nick" },
        { text: this.$t("FREQUENT_ADDRESSES.FULL_ADDRESS_1"), value: "Street" },
        {
          text: this.$t("FREQUENT_ADDRESSES.POSTAL_CODE_1"),
          value: "PostalCode"
        },
        { text: this.$t("FREQUENT_ADDRESSES.CITY"), value: "City" },
        { text: this.$t("FREQUENT_ADDRESSES.PROVINCE"), value: "ProvinceID" },
        { text: this.$t("GENERIC.ACTIONS"), width: 180, sortable: false }
      ],
      options: {},
      ProvinceOptions: [],
      ProvinceNames: [],
      AddressTypeOptions: [],
      AddressTypeNames: [],
      SortBy: "Nick",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByProvinceID: 0,
      FilterByAddressTypeID: 0,
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadProvinceOptions();
  },

  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      this.ProvinceNames = [];
      this.ProvinceNames[0] = " ";

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadAddressTypeOptions();
      });
    },

    loadAddressTypeOptions() {
      this.AddressTypeOptions = [];
      this.AddressTypeOptions.push({ AddressTypeID: 0, Name: " " });

      this.AddressTypeNames = [];
      this.AddressTypeNames[0] = " ";

      AddressTypeService.listAll().then(response => {
        response.forEach(at => {
          this.AddressTypeOptions.push(at);
          this.AddressTypeNames[at.AddressTypeID] = at.Name;
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchAddresses(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(AddressService.getCachedFilters()).forEach(f => {
        this["FilterBy" + f[0]] = f[1];

        if (!anyFilterNotEmpty) {
          anyFilterNotEmpty = parseInt(f[1]) > 0;
        }
      });

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchAddresses(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      AddressService.search(
        this.SearchText,
        this.FilterByProvinceID,
        this.FilterByAddressTypeID,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.Addresses;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchAddresses(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchAddresses(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchAddresses(false);
    },

    changeItemsCurrentPage() {
      this.searchAddresses(true);
    },

    cleanFilters() {
      this.FilterByProvinceID = 0;
      this.FilterByAddressTypeID = 0;
      this.ShowFilters = false;
      this.searchAddresses(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchAddresses(false);
      }, 600);
    },

    getProvinceName(pID) {
      let name = this.ProvinceNames[pID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    getAddressTypeName(atID) {
      let name = this.AddressTypeNames[atID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    deleteAddress(item) {
      Swal.fire({
        title: this.$i18n.t("GENERIC.DELETE_ALERT") + "\n" + item.Nick,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          AddressService.deleteProfile(item.AddressID).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("GENERIC.DELETE_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              this.searchAddresses(true);
            }
          });
        }
      });
    },

    startNewPriceSearchByAddress(addressID) {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch/step1?AddressID=" + addressID);
    }
  }
};
</script>
