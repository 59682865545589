<template>
  <!--begin::TenderRoutes index-->
  <div class="gray-box">
    <div class="search-info mb-6">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("TENDER_ROUTES.TITLE_INDEX_INFO") }}
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("TENDER_ROUTES.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg v-if="ShowFilters" src="/media/icons/filter-alt-1.svg" />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-primary mb-3"
            @click="$router.push('/manager/tenderroutes/new')"
          >
            <inline-svg src="/media/icons/add.svg" class="mr-3" />
            {{ $t("TENDER_ROUTES.TITLE_ADD_1") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-3">
          <label>
            {{ $t("TENDER_ROUTES.ORIGIN") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByOriginProvinceID"
            @change="searchTenderRoutes(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-3">
          <label>
            {{ $t("TENDER_ROUTES.DESTINATION") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByDestinationProvinceID"
            @change="searchTenderRoutes(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-6 pt-7 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ getProvinceName(item.OriginProvinceID) }}
          </td>
          <td v-html="formatAddressData(item.OriginAddress)"></td>
          <td>
            {{ getProvinceName(item.DestinationProvinceID) }}
          </td>
          <td v-html="formatAddressData(item.DestinationAddress)"></td>
          <td>
            <span
              class="white-box border-2 p-3 font-size-sm text-uppercase"
              :class="[item.FaresWaitingApproval === 0 ? 'opacity-50' : '']"
              style="white-space: nowrap"
            >
              {{
                $tc(
                  "TENDER_ROUTES.FARES_WAITING_APPROVAL",
                  item.FaresWaitingApproval
                )
              }}
            </span>
          </td>
          <td>
            <span
              class="mr-4 p-0"
              @click="startNewPriceSearchByTenderRoute(item.TenderRouteID)"
            >
              <inline-svg
                class="pricesearch-icon bg-tertiary"
                src="/media/icons/tarifas.svg"
              />
            </span>

            <span
              class="action-icon"
              @click="
                $router.push('/manager/tenderroutes/edit/' + item.TenderRouteID)
              "
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>

            <span class="action-icon ml-4" @click="deleteTenderRoute(item)">
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchTenderRoutes(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::TenderRoutes index-->
</template>

<script>
import Swal from "sweetalert2";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import TenderRouteService from "@/core/services/api/v2/tenderroute.service";

export default {
  name: "TenderRoutesIndex",
  data() {
    return {
      TableHeaders: [
        { text: this.$t("TENDER_ROUTES.ORIGIN"), value: "OriginProvinceID" },
        {
          text: this.$t("TENDER_ROUTES.ORIGIN_ADDRESS"),
          value: "OriginAddress.Nick"
        },
        {
          text: this.$t("TENDER_ROUTES.DESTINATION"),
          value: "DestinationProvinceID"
        },
        {
          text: this.$t("TENDER_ROUTES.DESTINATION_ADDRESS"),
          value: "DestinationAddress.Nick"
        },
        { text: this.$t("TENDER_ROUTES.STATUS"), value: "TenderRouteID" },
        { text: this.$t("GENERIC.ACTIONS"), width: 180, sortable: false }
      ],
      options: {},
      ProvinceOptions: [],
      ProvinceNames: [],
      SortBy: "OriginProvinceID",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByOriginProvinceID: 0,
      FilterByDestinationProvinceID: 0,
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadProvinceOptions();
  },

  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      this.ProvinceNames = [];
      this.ProvinceNames[0] = " ";

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchTenderRoutes(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(TenderRouteService.getCachedFilters()).forEach(f => {
        this["FilterBy" + f[0]] = f[1];

        if (!anyFilterNotEmpty) {
          anyFilterNotEmpty = parseInt(f[1]) > 0;
        }
      });

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchTenderRoutes(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      TenderRouteService.search(
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.TenderRoutes;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchTenderRoutes(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchTenderRoutes(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchTenderRoutes(false);
    },

    changeItemsCurrentPage() {
      this.searchTenderRoutes(true);
    },

    cleanFilters() {
      this.FilterByOriginProvinceID = 0;
      this.FilterByDestinationProvinceID = 0;
      this.ShowFilters = false;
      this.searchTenderRoutes(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchTenderRoutes(false);
      }, 600);
    },

    getProvinceName(pID) {
      let name = this.ProvinceNames[pID];
      if (!name) {
        name = " - ";
      }

      return name;
    },

    formatAddressData(ad) {
      let text = " - ";
      if (ad) {
        text =
          "<strong>" +
          ad.Nick +
          "</strong><br />" +
          ad.Street +
          "<br />" +
          ad.PostalCode +
          " - " +
          ad.City;
      }

      return text;
    },

    deleteTenderRoute(item) {
      Swal.fire({
        title:
          this.$i18n.t("GENERIC.DELETE_ALERT") +
          "\n" +
          this.getProvinceName(item.OriginProvinceID) +
          " - " +
          this.getProvinceName(item.DestinationProvinceID),
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          TenderRouteService.deleteProfile(item.TenderRouteID).then(
            statusCode => {
              if (statusCode >= 400) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                Swal.fire({
                  text: this.$i18n.t("GENERIC.DELETE_OK"),
                  icon: "success",
                  confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                  customClass: {
                    confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                  },
                  buttonsStyling: false,
                  focusConfirm: false
                });

                this.searchTenderRoutes(true);
              }
            }
          );
        }
      });
    },

    startNewPriceSearchByTenderRoute(tenderRouteID) {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch/step1?TenderRouteID=" + tenderRouteID);
    }
  }
};
</script>
